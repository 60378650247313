<template>
    <div>
        <b-overlay :show="isSavingCondos" >
            <b-card>
                <div class="head-create">
                    <h1>Crear condominio</h1>
                    <b-button
                        :to="{ name: 'condominiums'}"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    </b-button>
                </div>

                <ValidationObserver ref="createGroupCondos" v-slot="{ invalid }">
                    <b-form @submit.prevent="saveCondosForms" class="mt-1">
                        <div class="border rounded mb-1">
                            <b-row class="p-1">
                                <b-col cols="12"><h5>Información General</h5></b-col>
                                <b-col md="6">
                                    <ValidationProvider rules="required" name="name">
                                        <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                type="text"
                                                v-model="name"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="Ingrese el Nombre"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <ValidationProvider name="Hotel" rules="required">
                                        <b-form-group label="Resort" slot-scope="{ valid, errors }">
                                            <b-form-select
                                                :state="errors[0] ? false : valid ? true : null"
                                                v-model="resorts"
                                            >
                                                <option value="">Seleccione hotel</option>
                                                <option
                                                    v-for="hotel in filteredHotels"
                                                    :key="hotel.id"
                                                    :value="hotel.id"
                                                >
                                                    {{ hotel.name }}
                                                </option>
                                            </b-form-select>
                                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <ValidationProvider rules="required" name="address">
                                        <b-form-group label="Dirección" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                type="text"
                                                v-model="address"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="Ingrese la Dirección"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <ValidationProvider rules="required" name="zip">
                                        <b-form-group label="Código postal" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                type="number"
                                                v-model="zip"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="Ingrese el Código postal"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                            </b-row>
                        </div>
                        <div class="border rounded mb-1">
                            <b-row class="p-1">
                                <b-col cols="12"><h5>Información Bancaria</h5></b-col>

                                <b-col md="6">
                                    <ValidationProvider rules="required" name="bankName">
                                        <b-form-group label="Banco" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                type="text"
                                                v-model="bankName"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="Ingrese la Cuenta bancaria"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <ValidationProvider rules="required" name="bankAccount">
                                        <b-form-group label="Cuenta bancaria" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                type="text"
                                                v-model="bankAccount"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="Ingrese la Cuenta bancaria"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <ValidationProvider rules="required" name="swift">
                                        <b-form-group label="Swift" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                type="text"
                                                v-model="swift"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="Ingrese Swift"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <ValidationProvider rules="required" name="bankAdress">
                                        <b-form-group label="Domicilio del Banco" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                type="text"
                                                v-model="bankAdress"
                                                :state="errors[0] ? false : valid ? true : null"
                                                placeholder="Ingrese la Cuenta bancaria"
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col md="6">
                                    <div class="float-left pt-2 mt-1">
                                        <b-form-checkbox
                                            v-model="status"
                                            :value="true"
                                            class="custom-control-success"
                                        >
                                            Activado
                                        </b-form-checkbox>
                                    </div>
                                </b-col>

                            </b-row>
                        </div>

                        <b-row>
                            <b-col>
                                <div class="float-right">
                                    <b-button
                                        type="submit"
                                        class="btn-block"
                                        variant="primary"
                                        :disabled="invalid || isSavingCondos"
                                    >
                                        <b-spinner small v-if="isSavingCondos" /> Guardar
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </b-card>
        </b-overlay>
    </div>
</template>



<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { showAlertMessage } from "@/helpers/helpers";
import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },
    async created() {
        // await this.getInitialContentFivesClub();
    },
    data() {
        return {
            name: "",
            address: "",
            zip: null,
            bankAccount: null,
            resorts: "",
            status: true,
            isSavingCondos: false,
            bankName: "",
            swift: "",
            bankAdress: ""
        };
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("start", ["hotels"]),
        filteredHotels() {
            return this.hotels;
        },
    },
    methods: {
        ...mapActions("fivesClubCatalogs", ["saveCondos", "fetchCondos"]),
        ...mapMutations("fivesClubCatalogs", ["setCondos"]),
        ...mapActions("fivesClubCatalogs", ["getInitialContentFivesClub"]),
        async saveCondosForms() {
            this.isSavingCondos = true;
            const payload = {
                idUser: this.user.idUser,
                name: this.name,
                street: this.street,
                address: this.address,
                zip: this.zip,
                bankAccount: this.bankAccount,
                resorts: this.resorts,
                status: Boolean(this.status),
                bankName: this.bankName,
                swift: this.swift,
                bankAdress: this.bankAdress
            };
            const { status, message } = await this.saveCondos(payload);
            if (status) {
                showAlertMessage( "Ok: proceso finalizado", "InfoIcon", message, "success", 4000, "bottom-right" )
                Object.assign(this.$data, this.$options.data());
                this.$refs.createGroupCondos.reset(); //reseteo form
                //vuelvo a pedir la info del back
                const listCondos = await this.fetchCondos();
                this.setCondos(listCondos);
            }
            this.isSavingCondos = false;
        },
    },
};
</script>

<style scoped>
.head-create{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head-create h1{
    font-size: 1.5rem;
    color: #000;
}
</style>